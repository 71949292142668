.ac-form-section {
    background: #064635;
    display: block;
    text-align: center;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    width: 100%;
}

.ac-form-section p {
    color: #F0BB62;
    font-weight: 700;
    font-size: 1.5vw;
    margin: 0.5vw 0 1vw;
}

form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    width: 93%;
}

.form-group .input {
    width: 43%;
    display: block;
    margin-bottom: 1vw;
}

.form-group .input input {
    display: block;
    overflow: hidden;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0.3vw;
    direction: rtl;
    padding: 0.5vw 0.4vw;
    border-color: #064635;
    width: 94%;
    border: 0;
}

.form-group .input.input-checkbox {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.form-group .input.input-checkbox input[type="checkbox"] {
    width: 1vw;
}

.form-group .input.input-checkbox {
    color: white;
    font-size: 0.77vw;
    margin-bottom: 0;
}

.form-group .input_final {
    margin: 1vw 0;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    width: 90%;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
}

.form-group .input_final button {
    background: #F0BB62 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0.3vw;
    border: unset;
    letter-spacing: 0px;
    color: #484848;
    font-family: 'Rubik';
    font-weight: 700;
    padding: 0.3vw 2vw;
    font-size: 1vw;
}

.form-group .input span {
    color: red;
    font-size: 1.2vw;
}

.form-group p.error {
    display: block;
    position: absolute;
    top: 0.5vw;
    font-size: 1.2vw;
    color: red;
    right: 1vw;
}

.form-group .input:nth-child(3),
.form-group .input:nth-child(4) {
    margin-bottom: 0
}

@media(max-width:690px) {
    .ac-form-section {
        width: 100%;
        border-radius: unset;
        margin-top: 4vw;
        padding-bottom: 3vw;
    }
    .ac-form-section p {
        font-size: 5.5vw;
        margin: 4vw 0;
    }
    .form-group {
        flex-direction: column;
        /* margin:5vw auto; */
    }
    .form-group .input {
        width: 84%;
        display: flex;
        margin: 0.5vw auto;
    }
    .form-group .input input {
        width: 100%;
        padding: 2vw;
        margin: 1vw 0;
        border-radius: 1vw;
    }
    .form-group .input_final {
        width: 84%;
        justify-content: space-between;
        margin: 3vw 0;
    }
    .form-group .input.input-checkbox {
        font-size: 3vw;
        width: 70%;
        text-align: right;
    }
    .form-group .input.input-checkbox input[type="checkbox"] {
        width: 4vw;
        margin-top: 0;
        margin-left: 2vw;
        position: relative;
        top: -0.5vw;
    }
    .form-group .input_final button {
        font-size: 5vw;
        padding: 1vw 6vw;
        border-radius: 1vw;
    }
    .form-group .input input {
        border: unset;
    }
}