.ac_footer_page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    align-content: center;
    flex-wrap: nowrap;
    width: 100%;
    background-color: #064635;
    border-top: 1.5vw solid #519259;
    min-height: 6vw;
}

.ac_social_icon {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: row;
}

.ac_social_icon a {
    color: #519259;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 1.2vw;
}

.ac_social_icon a span {
    display: inline;
    margin-left: 1vw;
}

.ac_row_1_footer {
    width: 21vw;
    position: relative;
    right: -7vw;
}

.ac_row_1_footer img.ac_img_1 {
    width: 2vw;
    height: 2vw;
}

.ac_row_1_footer img.ac_img_1:hover {
    transform: scale(1.4);
}

.ac_row_2_footer {
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    position: relative;
}

.ac_row_2_footer .ac-circle-1,
.ac_row_2_footer .ac-circle-2 {
    width: 7vw;
    position: absolute;
    top: -7vw;
}

.ac_row_2_footer .ac-circle {
    box-shadow: inset 0.2vw -0.1vw 0.9vw 0.1vw #00000057;
    -moz-box-shadow: inset 0.2vw -0.1vw 0.9vw 0.1vw #00000057;
    -webkit-box-shadow: inset 0.2vw -0.1vw 0.9vw 0.1vw #00000057;
    width: 7vw;
    height: 7vw;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    color: #000;
}

.ac_row_2_footer .ac-circle img {
    width: 3.3vw;
}

.ac_row_2_footer .ac-circle span {
    font-size: 0.7vw;
    font-weight: 600;
    margin-top: 0.1vw;
}

.ac_row_2_footer .ac-circle-1 {
    right: 17vw;
    left: unset;
}

.ac_row_2_footer .ac-circle-2 {
    right: 8vw;
    left: unset;
}

@media (max-width: 690px) {
    .ac_footer_page {
        flex-direction: column-reverse;
        justify-content: space-between;
        min-height: 30vw;
        padding-bottom: 10vw;
        margin-top: 20vw;
    }
    .ac_row_2_footer .ac-circle-2,
    .ac_row_2_footer .ac-circle-1 {
        position: relative;
        top: unset;
    }
    .ac_row_2_footer,
    .ac_row_1_footer {
        width: 100%;
        align-items: center;
        justify-content: space-evenly;
    }
    .ac_row_1_footer {
        right: 0;
        z-index: 9;
    }
    .ac_social_icon {
        justify-content: space-evenly;
        width: 70%;
        margin: 0 auto;
    }
    .ac_row_1_footer .ac_social_icon img.ac_img_1 {
        width: 4vw;
        height: 4vw;
    }
    .ac_row_1_footer .ac-circle-1 {
        width: 6vw;
        height: 6vw;
        margin: 0 4vw;
    }
    .ac_row_2_footer .ac-circle-2,
    .ac_row_2_footer .ac-circle-1 {
        width: 35vw;
        height: 35vw;
        top: -13vw;
        left: unset;
        right: unset;
    }
    .ac_row_2_footer .ac-circle {
        -webkit-box-shadow: inset 0.5vw -0.1vw 1.9vw 0.5vw #00000057;
        box-shadow: inset 0.5vw -0.1vw 1.9vw 0.5vw #00000057;
        -moz-box-shadow: inset 0.2vw -0.1vw 0.9vw 0.1vw #00000057;
    }
    .ac_row_2_footer .ac-circle img {
        width: 17.3vw;
    }
    .ac_row_2_footer .ac-circle span {
        font-size: 2.7vw;
        font-weight: 600;
        margin-top: 1.1vw;
    }
    .ac_row_2_footer a {
        text-decoration: none;
    }
    .ac_row_2_footer {
        position: absolute;
    }
    .ac_social_icon a {
        font-size: 4.2vw;
    }
    .ac_social_icon a span {
        margin-left: 3vw;
    }
}