.ac_home_page {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
}

.ac_row_1,
.ac_row_2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 50%;
}

.ac_row_1 {
    display: block;
}

.ac_row_1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ac_row_2_5,
.ac_row_2_4,
.ac_row_2_3,
.ac_row_2_2,
.ac_row_2_1 {
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 67%;
    line-height: 1;
}

.ac_row_2_1 {
    margin-top: 1vw;
}

.ac_row_2_1 img {
    width: 10vw;
    height: auto;
    position: relative;
    right: -1vw;
}

span.text_ac_row_2_1 {
    color: #064635;
    font-size: 9vw;
    font-weight: 900;
}

.ac_row_2_2 {
    /* justify-content: space-evenly; */
    justify-content: center;
    padding-right: 2vw;
}

.ac_row_2_2 .ac-blue {
    color: #4b8cff;
    font-size: 5vh;
    font-weight: 900;
}

.ac_row_2_4 {
    justify-content: center;
    margin: 0.5vw 0;
}

span.ac_row_2_3_1,
span.ac_row_2_2_1 {
    color: #064635;
    font-size: 3vw;
    font-weight: 900;
}

.ac_row_2_3 {
    justify-content: center;
}

span.ac_row_2_3_1 {
    font-size: 4.4vw;
    text-align: center;
    display: inherit;
    letter-spacing: 0.1vw;
}

span.ac_row_2_4_2 {
    color: #f0bb62;
}

.ac_row_2_2 img {
    width: 9vw;
    height: auto;
    position: relative;
    right: -2vw;
}

.ac_row_2_4_block {
    background: #519259;
    color: white;
    border: 1px dashed white;
    transform: rotate(-5deg);
    border-radius: 5px;
    padding: 0.5vw 1.5vw;
    direction: rtl;
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

span.ac_row_2_4_1,
span.ac_row_2_4_3 {
    font-size: 1.9vw;
}

span.ac_row_2_4_2 {
    width: 1.3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5vw;
    position: relative;
    top: 0.13vw;
}

span.ac_row_2_5_1 {
    color: #064635;
    font-size: 2vw;
    font-weight: 700;
    display: block;
    text-align: center;
    margin-top: 0.5vw;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ac_row_2_5 {
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    margin-top: 1vw;
}

span.ac_strong_color {
    color: #519259;
}

.ac_row_2_6 {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 58%;
    margin-top: 1vw;
    /* position: relative;
    right: -1.5vw; */
}

.ac_row_2 {
    margin-bottom: 5vw;
}

.ac_desktop {
    display: block;
}

.ac_mobile {
    display: none;
}

@media (max-width: 690px) {
    .ac_home_page {
        flex-direction: column;
    }
    .ac_desktop {
        display: none;
    }
    .ac_mobile {
        display: block;
    }
    .ac_row_1 {
        width: 100%;
    }
    .ac_row_1 img {
        width: 100%;
        height: 43vw;
        object-fit: cover;
        object-position: top center;
    }
    .ac_row_2_5,
    .ac_row_2_4,
    .ac_row_2_3,
    .ac_row_2_2,
    .ac_row_2_1 {
        flex-direction: column;
        width: max-content;
    }
    .ac_row_2_1 img {
        width: 40vw;
        height: auto;
        object-fit: contain;
        position: absolute;
        left: 50%;
        right: unset;
        /* top: 50%; */
        top: 39vw;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .ac_row_2 {
        width: 100%;
        margin: 2vw auto;
        margin-top: 14vw;
    }
    span.text_ac_row_2_1 {
        font-size: 19vw;
    }
    .ac_row_2_2 {
        flex-direction: row-reverse;
    }
    span.ac_row_2_2_1 {
        font-size: 8.5vw;
    }
    span.ac_row_2_3_1 {
        font-size: 12.5vw;
        margin-top: -0.5vw;
    }
    .ac_row_2_2 img {
        width: 23vw;
        right: 1vw;
    }
    .ac_row_2_4_block {
        width: max-content;
        transform: rotate(-3deg);
        padding: 2vw 4vw;
        width: 97%;
    }
    .ac_row_2_5 {
        margin-top: 3vw;
    }
    span.ac_row_2_5_1 {
        font-size: 6vw;
    }
    .ac_row_2_4_block span {
        font-size: 6vw;
    }
    .ac_row_2_4_block span,
    span.ac_row_2_5_1 {
        display: block;
    }
    .ac_row_2_4_3 {
        width: 50%;
    }
    span.ac_row_2_4_2 {
        width: 5.3vw;
        height: 5.3vw;
    }
    span.ac_row_2_4_2 svg {
        width: 100%;
        height: 100%;
    }
    .ac_row_2_6 {
        width: 100%;
    }
}

.input_final button {
    cursor: pointer;
}

@media (min-width: 1000px) {
    .ac_home_page {
        height: 100vh;
    }
    .ac_row_1,
    .ac_row_2 {
        height: 84vh;
    }
    .ac_footer_page {
        min-height: unset;
        min-height: 13vh !important;
        border-top: 2.9vh solid #519259 !important;
    }
    .ac_row_1 img {
        width: 100%;
        height: 95vh;
        object-fit: cover;
    }
    .ac_row_2 {
        margin-bottom: 0vh;
    }
    span.text_ac_row_2_1 {
        font-size: 15vh;
    }
    .ac_row_2_1 img {
        width: 25%;
    }
    .ac_row_2_4 {
        margin: 1.5vh 0;
    }
    .ac_row_2_1 {
        margin-top: 1vh;
    }
    span.ac_row_2_3_1,
    span.ac_row_2_2_1 {
        font-size: 5vh;
    }
    .ac_row_2_2 img {
        width: 23%;
        right: -3vw;
    }
    span.ac_row_2_5_1 {
        font-size: 3.5vh;
        margin-top: 1vh;
    }
    .ac-form-section p {
        font-size: 3vh !important;
        margin: 1vh 0 1.5vh !important;
    }
    .ac_row_2_footer .ac-circle {
        width: 13vh;
        height: 13vh;
    }
    .ac_row_2_footer .ac-circle span {
        font-size: 1.3vh;
        font-weight: 600;
        margin-top: 0.1vh;
    }
    .ac_row_2_footer .ac-circle img {
        width: 6.5vh;
    }
    .ac_row_2_6 {
        width: 55vh;
        margin-top: 2vh;
    }
    .form-group .input input {
        border-radius: 0.3vw !important;
        padding: 1vh 1vh !important;
        width: 21vh !important;
    }
    .form-group .input {
        margin-bottom: 1.5vh !important;
    }
    .form-group .input_final {
        margin: 1vh 0 !important;
    }
    .form-group .input_final button {
        padding: 0.5vh 3vh !important;
        font-size: 2vh !important;
    }
    .form-group .input.input-checkbox {
        font-size: 1.5vh !important;
    }
    .form-group .input.input-checkbox input[type="checkbox"] {
        width: 2vh !important;
    }
    .ac_row_2_5,
    .ac_row_2_4,
    .ac_row_2_3,
    .ac_row_2_2,
    .ac_row_2_1 {
        width: 61vh;
    }
    span.ac_row_2_4_1,
    span.ac_row_2_4_3 {
        font-size: 4.2vh;
    }
}